import { AppDispatch, RootState } from "@/store/index";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useCobranding = () => {
  const email = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserObj?.email || '',
  );

  const [isCobranding, brandLogo] = useMemo(() => {
    const userDomain = email.split('@')[1];

    if (!!userDomain && userDomain === 'crispinsights.io') {
      return [true, '/crisp.png'];
    }

    return [false, null];
  }, [email])

  return {isCobranding, brandLogo}
}

export default useCobranding;